import React from 'react';
import { useQuery } from 'react-query';
import { TrialBalance } from '@kwara/models/src/models/v1/TrialBalance/Report';
import { formatIsoDate } from '@kwara/lib/src/dates';

async function fetchTrialBalance({ queryKey }) {
  const [_key, startDate, endDate] = queryKey;

  return TrialBalance.extraParams({
    start_date: formatIsoDate(startDate),
    end_date: formatIsoDate(endDate)
  })
    .find()
    .then(res => res.data);
}

export function useTrialBalance({ startDate, endDate }) {
  return useQuery(['trial_balance', startDate, endDate], fetchTrialBalance, {
    enabled: !!startDate && !!endDate,
    retry: false
  });
}

export function useTrialBalancePage() {
  const [params, setParams] = React.useState({
    startDate: null,
    endDate: null
  });

  const { data: report, isLoading } = useTrialBalance(params);

  const onSubmit = React.useCallback(data => setParams(prev => ({ ...prev, ...data })), []);

  return { report, onSubmit, isLoading };
}
