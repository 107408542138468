import * as React from 'react';
import cx from 'classnames';
import { Form as ReactFinalForm } from 'react-final-form';

import createValidator from '@kwara/lib/src/validator';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { If } from '@kwara/components/src/If/If';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { getCurrentDate } from '@kwara/lib/src/dates';

import styles from '../index.module.scss';

export function Form({ onSubmit }) {
  return (
    <ReactFinalForm
      onSubmit={data => onSubmit(data)}
      validate={createValidator({
        endDate: {
          isRequired: () => true,
          isNotFuture: true
        }
      })}
      render={({ hasValidationErrors, handleSubmit, hasSubmitErrors, submitting }) => {
        return (
          <form className="w-100 flex items-center" aria-label="Account Report Form" onSubmit={handleSubmit}>
            <div className="dib mr3">
              <SubscribedDatePicker
                disabled={submitting}
                disabledDays={{ after: getCurrentDate() }}
                showInfo={false}
                compact
                margin={false}
                required
                name="endDate"
                leftGlyph="AccountingReports.Index.Form.EndDate.leftGlyph"
              />
            </div>

            <div className={styles.submit}>
              <Button
                disabled={hasValidationErrors || submitting}
                isSubmit
                type="primary"
                glyphRightId={submitting ? Button.Glyphs.Spinner : null}
              >
                <Text id="AccountingReports.Index.Form.submit.generate" />
              </Button>
              <If
                condition={hasSubmitErrors}
                do={
                  <span className={cx('red-500', styles.submit__error)}>
                    <Text id="AccountingReports.Index.Form.submit.error" />
                  </span>
                }
              />
            </div>
          </form>
        );
      }}
    />
  );
}
