import React from 'react';
import { BodyContentWrapper } from '../../../../layouts';

import { Form } from './components/Form';
import { ReportsTable as Table } from './components/Table';
import { useProfitLossPage } from './useProfitLossPage';

export function ProfitLoss(_props) {
  const { onSubmit, report } = useProfitLossPage();

  return (
    <BodyContentWrapper.Root>
      <BodyContentWrapper.Row.Root>
        <div className="kw-text-x-large kw-weight-bold pa3">Profit & Loss</div>
        <Form onSubmit={onSubmit} />
      </BodyContentWrapper.Row.Root>
      <BodyContentWrapper.Row.Root>
        {report ? (
          <BodyContentWrapper.Row.Table>
            <Table report={report} isLoading={false} />
          </BodyContentWrapper.Row.Table>
        ) : null}
      </BodyContentWrapper.Row.Root>
    </BodyContentWrapper.Root>
  );
}
