import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Loadable } from '@kwara/components/src/Loadable';
import { useLoan } from '@kwara/models/src/models/request/hooks';
import { LoanTransaction, LoanType } from '@kwara/models/src';
import { formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';

import Wizard from '../../../../components/Wizard';
import { WizardPageProps } from '../../..';
import { loanPath } from '../../../../lib/urls';
import { steps } from './steps';

type Props = WizardPageProps<{ memberId: string }>;

type TransactionFormDataT = {
  type: 'REPAYMENT';
  accountId: string;
  amount: number;
  method: string;
  notes: string;
  reference: string;
  valueDate: string;
};

async function addRepayment(data: TransactionFormDataT) {
  const { amount, accountId: loanId, method, notes, reference, valueDate } = data;

  const transaction = LoanTransaction.create({
    type: 'REPAYMENT',
    loanId,
    amount,
    paymentMethodId: method,
    notes,
    reference,
    valueDate: formatIsoDate(valueDate)
  });

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
}

export function LoanRepayment(props: Props) {
  const queryClient = useQueryClient();
  const loanId = props.match.params.loanId;
  const r = useLoan(loanId);

  return (
    <Loadable {...r}>
      {(loan: LoanType) => {
        const member = loan.member;

        return loan.canMakeRepayment() ? (
          <Wizard
            analyticsId="LoanRepayment"
            baseUrl={loanPath({ id: loan.id })}
            history={history}
            initialData={{
              member,
              loan,
              accountId: loanId,
              valueDate: getCurrentDate()
            }}
            cancelReturnsTo={loanPath({ id: loan.id })}
            currentStep="repayment"
            onSubmit={addRepayment}
            onSubmitCompletion={() => {
              r.refetch();
              queryClient.invalidateQueries('member_details_metrics');
            }}
            steps={steps}
            startId="repayment"
            titleId="LoanRepayment.title"
          />
        ) : (
          <Redirect to={loanPath({ id: member.id })} />
        );
      }}
    </Loadable>
  );
}
