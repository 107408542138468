import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { If } from '@kwara/components/src/If/If';
import AuthExpiryChecker from '@kwara/components/src/AuthExpiryChecker';
import { NamedRoute, DetectUserActivity, PermissionDenied, NotFound404 } from '@kwara/components/src';

import * as pages from '../../../pages';
import { pagesV1 } from '../../../pages/v1';

import { AppBase } from '../../../layouts';
import { useAuth, usePermissions } from '../../../hooks';
import { ROUTES } from '../../utils/routes';
import Visible from '../../../components/Visible';
import { store } from '../../../models/Store/Store';
import { LoanRoutes } from '../LoanRoutes/LoanRoutes';
import { Redirect2FA } from '../../../components/Check2FA';
import { DevOnlyRoute } from '../DevOnlyRoute/DevOnlyRoute';
import { SavingsRoutes } from '../SavingsRoutes/SavingsRoutes';
import { MemberRoutes } from '../MembersRoutes/MembersRoutes';
import { PermittedRoute } from '../PermittedRoute/PermittedRoute';
import { KwaraPermissionsConfigKeyType } from '../../../models/Permission/types';

export function ProtectedRoutes({ activityChecker }: { activityChecker: DetectUserActivity }) {
  const [dismissed2fa, setDismissed2fa] = React.useState(false);
  const auth = useAuth();
  const { AppPermissions } = usePermissions();

  async function logOut() {
    await auth.logOut();
    window.location.replace(ROUTES.login);
  }

  const isV1 = auth.isV1();

  return (
    <AppBase logOut={logOut}>
      <AuthExpiryChecker<KwaraPermissionsConfigKeyType>
        auth={auth}
        logOut={logOut}
        getLastActivityTime={activityChecker.getLastActivityTime}
      >
        <If condition={!dismissed2fa} do={<NamedRoute path="/" name="Redirect2FA" component={Redirect2FA} />} />
        <Switch>
          {/* Redirects from /members to / */}
          <Route exact path="/members" render={() => <Redirect exact to="/" />} />
          <DevOnlyRoute name="Debug" path={ROUTES.debug} component={pages.Debug} fallback={NotFound404} />
          <PermittedRoute
            permission={[]}
            name="Members"
            exact
            path={ROUTES.members}
            component={props => <pages.Members {...props} isV1={isV1} />}
          />
          <PermittedRoute
            exact
            name="Savings"
            path={ROUTES.savings}
            component={pages.Savings}
            permission={AppPermissions.ViewSavings}
          />
          <PermittedRoute
            exact
            name="Loans"
            path={ROUTES.loans}
            component={pages.Loans}
            permission={AppPermissions.ViewLoan}
          />
          {/* Either load /loans/create or /loans/:loanId but not both */}
          <NamedRoute
            exact
            name="LoanAdd"
            path={ROUTES.loanAdd}
            render={props => (
              <Visible to={AppPermissions.AddLoans} fallback={<PermissionDenied />}>
                <pages.Loans {...props} />
                <pages.LoanAdd {...props} baseUrl="/loans/create" store={store} />
              </Visible>
            )}
          />
          <PermittedRoute
            permission={AppPermissions.ViewLoan}
            name="LoanRoutes"
            path={ROUTES.loanRoutes}
            component={LoanRoutes}
          />
          {/* Either load /members/create or /members/:memberId but not both */}
          <NamedRoute
            exact
            name="MemberAdd"
            path={ROUTES.memberAdd}
            render={({ history, match }) => (
              <Visible to={AppPermissions.AddMembers} fallback={<PermissionDenied />}>
                <Route exact path="/members/create.*" component={pages.Members} />
                <pages.MemberAdd history={history} match={match} baseUrl="/members/create" action="add" />
              </Visible>
            )}
          />
          <PermittedRoute
            permission={AppPermissions.ViewMembers}
            name="MemberRoutes"
            path={ROUTES.memberRoutes}
            component={MemberRoutes}
          />
          <NamedRoute
            exact
            name="MemberEdit"
            path={ROUTES.memberEdit}
            render={pars => (
              <Visible to={AppPermissions.AddMembers} fallback={<PermissionDenied />}>
                <pages.MemberAdd
                  history={pars.history}
                  match={pars.match}
                  baseUrl={`/members/${pars.match.params.memberId}/edit`}
                />
              </Visible>
            )}
          />
          {/* Either load /savings/create or /savings/:savingId but not both */}
          <NamedRoute
            exact
            name="SavingAdd"
            path={ROUTES.savingAdd}
            render={props => (
              <Visible to={AppPermissions.AddSavings} fallback={<PermissionDenied />}>
                {/* Renders list below modal */}
                <pages.Savings {...props} />
                <pages.SavingAdd {...props} baseUrl="/savings/create" />
              </Visible>
            )}
          />
          <PermittedRoute
            permission={AppPermissions.ViewSavings}
            name="SavingsRoutes"
            path={ROUTES.savingsRoutes}
            render={() => <SavingsRoutes />}
          />
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              permission={AppPermissions.UseTill}
              name="Till"
              path={ROUTES.till}
              render={props => <pages.Till {...props} />}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              permission={AppPermissions.UseTellerSupervisorPage}
              name="Tellers"
              path={ROUTES.tellers}
              render={props => <pages.Tellers {...props} />}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              permission={AppPermissions.ManageTillAccess}
              name="TillsManagement"
              path={ROUTES.tillsManagement}
              render={props => <pages.TillsManagement {...props} />}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              name="TopupRequest"
              permission={AppPermissions.CreateTopupRequests}
              path={ROUTES.topupRequest}
              render={props => <pages.TopupRequest {...props} baseUrl="/topup/new" />}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute<{ topupId: string }>
              exact
              name="TillTransactionReview"
              permission={AppPermissions.ReviewTopupRequest}
              path={ROUTES.topupReview}
              render={props => (
                <pages.TillTransactionReview {...props} baseUrl={`/topup/${props.match.params.topupId}`} />
              )}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              name="TillToVaultTransfer"
              permission={AppPermissions.CreateTopupRequests}
              path={ROUTES.tillToVaultTransfer}
              render={props => <pages.TillToVaultTransfer {...props} baseUrl="/tillToVault/new" />}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              name="UtilityPayment"
              permission={AppPermissions.CreateUtilityTransactions}
              path={ROUTES.utilityPayment}
              render={props => <pages.UtilityPayment {...props} baseUrl="/till" />}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              name="UtilityReceipt"
              permission={AppPermissions.CreateUtilityTransactions}
              path={ROUTES.utilityReceipt}
              render={props => <pages.UtilityReceipt {...props} baseUrl="/till" />}
            />
          )}
          {store.isDepositTaking && (
            <PermittedRoute
              exact
              name="TransactionDetail"
              permission={AppPermissions.ViewTillTransactions}
              path={ROUTES.transactionDetail}
              component={pages.TransactionDetail}
            />
          )}

          {store.isDepositTaking && (
            <PermittedRoute
              exact
              name="TillSessionDetail"
              permission={AppPermissions.ViewTills}
              path={ROUTES.tillSessionDetail}
              component={pages.TillSessionDetail}
            />
          )}

          <NamedRoute
            name="2faActivation"
            path={ROUTES['2faActivation']}
            render={props => <pages.Auth2faActivation onDismiss={() => setDismissed2fa(true)} {...props} />}
          />
          {store.isCrbCheckEnabled ? (
            <PermittedRoute
              exact
              name="CreditSubmit"
              permission={AppPermissions.CreditSubmit}
              path={ROUTES.creditSubmit}
              component={pages.CreditSubmit}
            />
          ) : null}
          <PermittedRoute
            exact
            name="BatchTransactionImportsCreate"
            permission={AppPermissions.CreateBatchTransactionImports}
            path={ROUTES.batchTransactionImportsCreate}
            component={pages.BatchTransactionImportsUpload}
          />
          <PermittedRoute
            exact
            name="BatchTransactionImports"
            permission={AppPermissions.ViewBatchTransactionImports}
            path={ROUTES.batchTransactionImports}
            component={pages.BatchTransactionImports}
          />
          <NamedRoute
            exact
            name="LoanProductAdd"
            path={ROUTES.loanProductAdd}
            render={props => <pages.LoanProductAdd baseUrl="/settings/loans/new" {...props} />}
          />
          <NamedRoute
            exact
            name="AppRoleAdd"
            path={ROUTES.appRoleAdd}
            render={props => <pages.AppRoleWizard baseUrl="/settings/roles/new" {...props} />}
          />
          <NamedRoute
            exact
            name="AppRoleEdit"
            path={ROUTES.appRoleEdit}
            render={props => (
              <pages.AppRoleWizard baseUrl={`/settings/roles/${props.match.params.roleId}/edit`} {...props} />
            )}
          />
          <NamedRoute
            exact
            name="LoanProductEdit"
            path={ROUTES.loanProductEdit}
            render={props => <pages.LoanProductEdit baseUrl="/settings/loans/:productId/edit" {...props} />}
          />
          <PermittedRoute
            exact
            admin
            permission={[]}
            name="UserEdit"
            path={ROUTES.userEdit}
            render={props => <pages.UserEdit baseUrl="/settings/team/:userId/edit" {...props} />}
          />
          <NamedRoute
            exact
            name="LoanSchedulePreview"
            path={ROUTES.loanSchedulePreview}
            render={props => <pages.LoanSchedulePreview baseUrl="/settings/loans/:productId/schedule" {...props} />}
          />
          <NamedRoute
            exact
            name="MemberReportGenerate"
            path={ROUTES.memberReportGenerate}
            render={props => <pages.MemberReportGenerate baseUrl="/settings/data/generate" {...props} />}
          />
          <NamedRoute
            exact
            name="SavingProductAdd"
            path={ROUTES.savingProductAdd}
            render={props => <pages.SavingProductAdd baseUrl="/settings/saving/new" {...props} />}
          />
          <NamedRoute
            exact
            name="SavingProductEdit"
            path={ROUTES.savingProductEdit}
            render={props => <pages.SavingProductEdit baseUrl="/settings/saving/:productId/edit" {...props} />}
          />
          <PermittedRoute
            exact
            name="InviteUser"
            permission={AppPermissions.InviteUser}
            path={ROUTES.inviteUser}
            render={props => <pages.InviteUser baseUrl="/settings/workspace/invite" {...props} />}
          />
          <PermittedRoute
            exact
            admin
            permission={[]}
            name="SMSRenew"
            path={ROUTES.smsRenew}
            render={props => <pages.RenewSMS baseUrl="/settings/sms" {...props} />}
          />
          <PermittedRoute
            exact
            admin
            permission={[]}
            name="SmsTriggersEdit"
            path={ROUTES.smsTriggersEdit}
            render={props => <pages.SmsTriggerEdit baseUrl="/settings/sms" {...props} />}
          />
          <PermittedRoute
            permission={AppPermissions.CreateJournalEntries}
            name="JournalEntryAdd"
            path={ROUTES.journalEntryAdd}
            render={props => <pages.JournalEntryAdd baseUrl="/finance/journalEntries/new" {...props} />}
          />
          <PermittedRoute
            permission={AppPermissions.GenerateDirectDebitReports}
            name="DirectDebitReports"
            path={ROUTES.directDebitReports}
            render={props => <pages.DirectDebitReportGenerate baseUrl="/finance/reports/generate" {...props} />}
          />
          <PermittedRoute
            permission={AppPermissions.CreateReconciliationStatements}
            name="ReconciliationsCreate"
            path={ROUTES.reconciliationReportCreate}
            render={props => <pages.ReconciliationsAdd baseUrl={ROUTES.reconciliationReportIndex} {...props} />}
          />
          {store.isSasraRegulated && (
            <NamedRoute
              name="SasraReport4BGenerate"
              path={ROUTES.sasraReport4BGenerate}
              render={props => <pages.SasraReport4BGenerate baseUrl="/finance/sasra/generate" {...props} />}
            />
          )}
          {store.isSasraRegulated && (
            <PermittedRoute
              permission={AppPermissions.ManageSasraReports}
              name="SasraReports"
              path={ROUTES.sasraReports}
              component={pages.SasraReports}
            />
          )}
          {store.isSasraRegulated && (
            <PermittedRoute
              permission={AppPermissions.ConfigureSasraReports}
              exact
              name="SasraReportTemplates"
              path={ROUTES.sasraReportTemplates}
              component={pages.SasraReportTemplates}
            />
          )}
          {store.isSasraRegulated && (
            <PermittedRoute
              permission={AppPermissions.ConfigureSasraReports}
              name="SasraReportConfig"
              path={ROUTES.sasraReportConfig}
              render={props => <pages.SasraReportConfig {...props} />}
            />
          )}
          <PermittedRoute
            exact
            name="guarantorListingReports"
            permission={AppPermissions.ViewGuarantorListingReports}
            path={ROUTES.guarantorListingReports}
            component={pages.GuarantorListingReports}
          />
          <PermittedRoute
            exact
            name="loanAgeingReports"
            permission={AppPermissions.ViewLoanAgeingReports}
            path={ROUTES.loanAgeingReports}
            component={pages.LoanAgeingReports}
          />
          <PermittedRoute
            exact
            name="loanListingReports"
            permission={AppPermissions.ViewLoanListingReports}
            path={ROUTES.loanListingReports}
            component={pages.LoanListingReports}
          />
          <PermittedRoute
            exact
            name="depositListingReports"
            permission={AppPermissions.ViewDepositListingReports}
            path={ROUTES.depositListingReports}
            component={pages.DepositListingReports}
          />
          <PermittedRoute
            exact
            name="checkOffAdviceReports"
            permission={AppPermissions.ViewCheckOffAdviceReports}
            path={ROUTES.checkOffAdviceReports}
            component={pages.CheckOffAdviceReports}
          />
          <PermittedRoute
            exact
            name="memberListingReports"
            permission={AppPermissions.ViewMemberListingReports}
            path={ROUTES.memberListingReports}
            component={pages.MemberListingReports}
          />
          <PermittedRoute
            exact
            name="accountingReports"
            permission={AppPermissions.ViewAccountingReports}
            path={ROUTES.accountingReports}
            render={props => <pages.ReportsIndex {...props} />}
          />
          <PermittedRoute
            exact
            name="v1/accountingReports/glReport"
            permission={AppPermissions.ViewAccountingReports}
            path={ROUTES.v1.accountingReports.glReport}
            render={props => <pagesV1.AccountingReports.GLReport {...props} />}
          />
          <PermittedRoute
            exact
            name="v1/accountingReports/trialBalance"
            permission={AppPermissions.ViewAccountingReports}
            path={ROUTES.v1.accountingReports.trialBalance}
            render={props => <pagesV1.AccountingReports.TrialBalance {...props} />}
          />
          <PermittedRoute
            exact
            name="v1/accountingReports/profitLoss"
            permission={AppPermissions.ViewAccountingReports}
            path={ROUTES.v1.accountingReports.profitLoss}
            render={props => <pagesV1.AccountingReports.ProfitLoss {...props} />}
          />
          <PermittedRoute
            exact
            name="v1/accountingReports/balanceSheet"
            permission={AppPermissions.ViewAccountingReports}
            path={ROUTES.v1.accountingReports.balanceSheet}
            render={props => <pagesV1.AccountingReports.BalanceSheet {...props} />}
          />
          <PermittedRoute
            exact
            name="v1/accountingReports/journalEntries"
            permission={AppPermissions.ViewJournalEntries}
            path={ROUTES.v1.accounting.journalEntries}
            component={pages.JournalEntryModule}
          />
          <PermittedRoute
            exact
            name="accountingReportView"
            permission={AppPermissions.ViewAccountingReports}
            path={ROUTES.accountingReportView}
            render={props => <pages.ReportView {...props} />}
          />
          <PermittedRoute
            permission={AppPermissions.ViewDashboardIndicators}
            name="Dashboard"
            path={ROUTES.dashboard}
            render={props => <pages.Dashboard {...props} />}
          />
          {store.dividendsFeatureLive && (
            <PermittedRoute
              permission={AppPermissions.UseDividendReports}
              name="DividendReportGenerate"
              path={ROUTES.dividendReportGenerate}
              render={props => (
                <pages.DividendReportGenerate
                  baseUrl="/finance/dividends/reports/dividend/generate"
                  reportType={'dividend'}
                  {...props}
                />
              )}
            />
          )}
          {store.dividendsFeatureLive && (
            <PermittedRoute
              permission={AppPermissions.UseDividendReports}
              name="InterestReportGenerate"
              path={ROUTES.interestReportGenerate}
              render={props => (
                <pages.DividendReportGenerate
                  baseUrl="/finance/dividends/reports/interest/generate"
                  reportType={'interest'}
                  {...props}
                />
              )}
            />
          )}
          {store.dividendsFeatureLive && (
            <PermittedRoute
              permission={AppPermissions.UseDividendReports}
              name="DividendReportsView"
              path={ROUTES.dividendReportsView}
              component={props => <pages.DividendReportView {...props} />}
            />
          )}
          {/**
           * @Begin
           * Finance Tab Navigation Routing
           */}
          <PermittedRoute
            permission={AppPermissions.ViewSavingTransactionsPage}
            name="PendingSavingTransactions"
            path={ROUTES.financeSavingTransactions}
            component={pages.PendingSavingTransactions}
          />
          <PermittedRoute
            permission={AppPermissions.ViewLoanTransactionsPage}
            name="PendingLoanTransactions"
            path={ROUTES.financeLoanTransactions}
            component={pages.PendingLoanTransactions}
          />
          <PermittedRoute
            permission={AppPermissions.ViewPendingLoanDisbursementsPage}
            name="PendingLoanDisbursements"
            path={ROUTES.financeLoanDisbursementTransactions}
            component={pages.PendingLoanDisbursements}
          />
          <PermittedRoute
            permission={AppPermissions.ViewMpesaTransactions}
            name="C2BTransactions"
            path={ROUTES.financeC2b}
            component={pages.C2BTransactions}
          />
          <PermittedRoute
            permission={AppPermissions.ViewMpesaTransactions}
            name="B2CTransactions"
            path={ROUTES.financeB2c}
            component={pages.B2CTransactions}
          />
          <PermittedRoute
            permission={AppPermissions.ViewMpesaTransactions}
            name="STKTransactions"
            path={ROUTES.financeStk}
            component={pages.STKTransactions}
          />
          <PermittedRoute
            permission={AppPermissions.ViewDirectDebitReports}
            name="DirectDebitReports"
            path={ROUTES.financeReports}
            component={pages.DirectDebitReports}
          />
          <PermittedRoute
            permission={AppPermissions.ViewLoan}
            name="LoanDisbursementReport"
            path={ROUTES.financeLoanDisbursementReport}
            component={pages.LoanDisbursementReport}
          />
          <PermittedRoute
            permission={AppPermissions.ViewChequeTransactions}
            name="ChequeReports"
            path={ROUTES.financeChequeReports}
            component={pages.ChequeReports}
          />
          <PermittedRoute
            permission={AppPermissions.ViewJournalEntries}
            name="JournalEntryModule"
            path={ROUTES.financeJournalEntries}
            component={pages.JournalEntryModule}
          />
          <PermittedRoute
            permission={AppPermissions.ViewReconciliationStatements}
            name="ReconcileTransactions"
            path={ROUTES.financeReconciliations}
            component={pages.ReconcileTransactions}
          />
          <NamedRoute name="SasraReport" path={ROUTES.financeSasra} component={pages.SasraReport} />
          <PermittedRoute
            permission={AppPermissions.UseDividendReports}
            name="JournalEntryAdd"
            path={ROUTES.financeDividends}
            component={pages.DividendReportsIndex}
          />
          {/**
           * @End
           * Finance Tab Navigation Routing
           */}
          {/**
           * @Begin
           * Settings SubNavigations Routing
           */}
          <NamedRoute name="Activity" path={ROUTES.settingsActivity} component={pages.Activity} />
          <NamedRoute name="Personal" path={ROUTES.settingsGeneral} component={pages.Personal} />
          <NamedRoute name="LoanProducts" path={ROUTES.settingsLoans} component={pages.LoanProducts} />
          <NamedRoute name="SavingProducts" path={ROUTES.settingsSavings} component={pages.SavingProducts} />
          {store.profile.isAdmin && <NamedRoute name="Team" path={ROUTES.settingsTeam} component={pages.Team} />}
          {store.profile.isAdmin && (
            <NamedRoute name="AppRoles" path={ROUTES.settingsRolesPermissions} component={pages.AppRoles} />
          )}
          {store.profile.isAdmin && (
            <NamedRoute
              name="ManageCredentials"
              path={ROUTES.settingsManageCredentials}
              component={pages.ManageCredentials}
            />
          )}
          {store.isKwaraKenyaOrE2E && store.profile.isAdmin && (
            <NamedRoute name="SmsSettings" path={ROUTES.settingsSmsSettings} component={pages.SmsSettings} />
          )}
          <PermittedRoute
            permission={AppPermissions.ViewOrgActivityFeed}
            name="Events"
            path={ROUTES.settingsEvents}
            component={pages.Events}
          />
          <PermittedRoute
            permission={AppPermissions.ViewInvitations}
            name="Workspace"
            path={ROUTES.settingsWorkspace}
            component={pages.Workspace}
          />
          {/**
           * @End
           * Settings SubNavigations Routing
           */}
          <Route path={ROUTES.notFound404} component={NotFound404} />
        </Switch>
      </AuthExpiryChecker>
    </AppBase>
  );
}
