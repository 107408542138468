import * as React from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import { useQueryClient } from 'react-query';

import { LoanFormData, LoanSubstepProps } from 'LoanSharedTypes';

import Wizard from '@kwara/components/src/Wizard/latest';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { MobilePayoutSettings } from '@kwara/models/src/models/OrganisationSetting';
import { LoanType, MemberType, IdDocumentType, DisbursalInfo } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';
import { isStandardIdDocumentType } from '@kwara/components/src/Intl';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { TransactionChannelT } from '@kwara/models/src/models/Transactions';

import { loanPath } from '../../../lib/urls';
import { steps } from './config';
import { useLoan } from './useLoan';
import { useAuth } from '../../../hooks';
import { useCanSubmit } from './useCanSubmit';
import { Store } from '../../../models/Store/Store';
import { getPaymentInfo } from '../utils/getPaymentInfo';
import { CreditCheckImp } from '../components/CreditCheck/CreditCheckImp';
import { useSaccoProfileContext } from '../../../models/Profile/ProfileProvider';

export interface LoanDisbursalData extends LoanFormData {
  bankGlId?: string;
  paymentMethod?: TransactionChannelT; // For Legacy API
  paymentMethodId?: string; // For v1 API
  transactionAmountLimit: number;
  floatBalance: number;
  payoutProvider: string | null;
  phoneNumberProvider: string | null;
  disburserOneAmount?: string;
  disburserTwoAmount?: string;
  anticipatedPayout?: string;
  isV1?: boolean; // Temporal to allow us turn off some wizard validations in the Approvals step
  v1Amount?: string;
}

export interface LoanDisbursalPropTypes extends LoanSubstepProps<LoanDisbursalData> {}

type DisburseArgs = {
  disburserOneAmount: string;
  disburserTwoAmount: string;
  disburserOneNote: string;
  disburserTwoNote: string;
  valueDate: Date;
} & Partial<LoanDisbursalData>;

export async function onDisburse(
  data: DisburseArgs,
  setDisbursementState: React.Dispatch<React.SetStateAction<string>>,
  isV1: boolean
) {
  const { v1Amount, loan, member, disburserOneAmount, disburserTwoAmount, disburserOneNote, disburserTwoNote } = data;
  const isDisburserOneDone = loan.hasDisbursementRecord();
  const legacyAmount = isDisburserOneDone ? disburserTwoAmount : disburserOneAmount;
  const notes = isDisburserOneDone ? disburserTwoNote : disburserOneNote;
  const valueDate = isDisburserOneDone ? null : formatIsoDate(data.valueDate);
  const paymentInfo = getPaymentInfo({ data, phoneNumber: member.phoneNumber, isV1 });
  const payload: DisbursalInfo = {
    amount: isV1 ? v1Amount : legacyAmount,
    notes,
    valueDate,
    ...(!isDisburserOneDone && paymentInfo)
  };

  const disbursementState: string = isV1 ? await loan.disburseV1(payload) : await loan.disburse(payload);

  if (!disbursementState) throw loan.errors;

  if (disbursementState === 'failed') {
    throw {
      meta: {
        fullMessage: 'Disbursal approval for this loan has failed. Please contact us to resolve this issue.'
      }
    };
  }

  setDisbursementState(disbursementState);
}

function MemberFetcher({
  loan,
  baseUrl,
  params
}: {
  loan: LoanType;
  baseUrl: string;
  params: LoanDisbursalLocationParams;
}) {
  const { collaterals, guarantors, product } = loan;

  const [disbursementState, setDisbursementState] = React.useState('pending_initial_approvals');
  const queryClient = useQueryClient();
  const memberR = useMember(loan.member.id);
  const canSubmit = useCanSubmit(get('loanApplication.disbursement', loan));
  const store: Store = useSaccoProfileContext();
  const auth = useAuth();

  const isV1 = auth.isV1();
  const mobilePayoutSettings = store?.organisationSettings?.mobilePayoutSettings || ({} as MobilePayoutSettings);

  return (
    <Loadable {...memberR}>
      {(member: MemberType) => {
        // KBS has idDocument type: Historical Member ID,
        // we need to ensure to pick either Passport or National
        const idDocument = find(
          (idDocument: IdDocumentType) => isStandardIdDocumentType(idDocument.type),
          get('idDocuments', member)
        );

        const data = {
          loan,
          identityValue: get('documentId', idDocument),
          identityType: get('type', idDocument)
        };

        return (
          <CreditCheckImp data={data}>
            {args => (
              <Wizard
                startId="loanDetails"
                analyticsId="LoanDisbursal"
                titleId="LoanDisbursal.title"
                initialData={{
                  ...data,
                  member,
                  collaterals,
                  guarantors,
                  product,
                  valueDate: getCurrentDate(),
                  phoneNumberProvider: null,
                  floatBalance: null,
                  isV1,
                  payoutProvider: mobilePayoutSettings.payout_provider,
                  transactionAmountLimit: mobilePayoutSettings.transaction_amount_limit
                }}
                steps={steps({
                  isCrbCheckEnabled: args.isCrbCheckEnabled,
                  canSubmit,
                  isKwaraKenyaOrE2E: store.isKwaraKenyaOrE2E
                })}
                baseUrl={baseUrl}
                onSubmit={async (data: DisburseArgs) => {
                  await onDisburse(data, setDisbursementState, isV1);
                }}
                currentStep={params.step}
                cancelReturnsTo={loanPath({ id: loan.id })}
                currentSubStep={params.subStep != undefined ? parseInt(params.subStep, 10) : null}
                completionAutoConfirm={false}
                onSubmitCompletion={() => queryClient.removeQueries('loan')}
                successSubtitleId={`LoanDisbursal.Approvals.completion.subtitle.${disbursementState}`}
              />
            )}
          </CreditCheckImp>
        );
      }}
    </Loadable>
  );
}

export type LoanDisbursalLocationParams = {
  step: string;
  loanId: string;
  subStep?: string;
};

export function LoanDisbursal({ baseUrl }: { baseUrl: string }) {
  const params = useParams<LoanDisbursalLocationParams>();
  const r = useLoan(params.loanId);

  return (
    <Loadable {...r}>{(loan: LoanType) => <MemberFetcher baseUrl={baseUrl} loan={loan} params={params} />}</Loadable>
  );
}
