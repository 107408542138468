import React from 'react';
import { useQuery } from 'react-query';
import { BalanceSheet } from '@kwara/models/src/models/v1/BalanceSheet/Report';
import { formatIsoDate } from '@kwara/lib/src/dates';

async function fetchBalanceSheet({ queryKey }) {
  const [_key, endDate] = queryKey;

  return BalanceSheet.extraParams({
    end_date: formatIsoDate(endDate)
  })
    .find()
    .then(res => res.data);
}

export function useBalanceSheet({ endDate }) {
  return useQuery(['balance_sheet', endDate], fetchBalanceSheet, {
    enabled: !!endDate,
    retry: false
  });
}

export function useBalanceSheetPage() {
  const [params, setParams] = React.useState({ endDate: null });

  const { data: report, isLoading } = useBalanceSheet(params);

  const onSubmit = React.useCallback(data => setParams(prev => ({ ...prev, ...data })), []);

  return { report, onSubmit, isLoading };
}
