import * as React from 'react';

import Asset from '@kwara/components/src/Asset';
import Button from '@kwara/components/src/Button';

import { If } from '@kwara/components/src/If/If';
import { Text } from '@kwara/components/src/Intl';
import { useDownloadButton } from '@kwara/components/src/DownloadButton';
import { JournalEntry as JournalEntryModel, JournalEntryT } from '@kwara/models/src';

import Visible from '../../../../../components/Visible';
import OverviewListV2 from '../../../../../components/OverviewListV2';

import { useAuth } from '../../../../../hooks';
import { JournalEntriesTable } from './Table';
import { financePath } from '../../../../../lib/urls';
import { ButtonMenu } from '../../../../../components/ButtonMenu';
import { AppPermissions } from '../../../../../models/Permission';

function DownloadButtonMenu({ where }) {
  const { onDownload: onDownloadPDF, isLoading: isDownloadingPDF } = useDownloadButton({
    downloadFn: () => JournalEntryModel.downloadPDF(where),
    disabled: false
  });

  const { onDownload: onDownloadXLSX, isLoading: isDownloadingXLSX } = useDownloadButton({
    downloadFn: () => JournalEntryModel.downloadXLSX(where),
    disabled: false
  });

  return (
    <ButtonMenu
      Button={props => (
        <Button {...props} disabled={isDownloadingPDF || isDownloadingXLSX}>
          <If
            condition={!isDownloadingPDF && !isDownloadingXLSX}
            do={<Text id="Finance.JournalEntries.button.download" />}
            else={<Asset col={Asset.Colours.grey300} id={Asset.Glyphs.Spinner} />}
          />
        </Button>
      )}
      type="secondary"
    >
      <ButtonMenu.Item disabled={isDownloadingPDF} onClick={onDownloadPDF}>
        <Text id="FileType.PDF" />
      </ButtonMenu.Item>
      <ButtonMenu.Item disabled={isDownloadingXLSX} onClick={onDownloadXLSX}>
        <Text id="FileType.Excel" />
      </ButtonMenu.Item>
    </ButtonMenu>
  );
}

type Props = {
  transactions: JournalEntryT[];
  actions?: (React.ReactElement<typeof Button> | React.ReactElement<typeof Visible>)[] | React.ReactElement;
  filterValue: string;
  totalResults: number;
  isLoading: boolean;
  hasMore: boolean;
  onNext(): void;
  where?: {
    from: string;
    to: string;
    gl_code?: string;
  };
};

export function JournalEntriesList({ transactions = [], totalResults, isLoading, where, ...r }: Props) {
  const auth = useAuth();

  const isV1 = auth.isV1();

  const actions = React.useMemo(() => {
    if (isV1) {
      return [
        <Visible key="new" to={AppPermissions.CreateJournalEntries}>
          <Button key="new" type="primary" to={financePath({ baseExtension: 'journalEntries', action: 'new' })}>
            <Text id="Finance.JournalEntries.add" />
          </Button>
        </Visible>
      ];
    }

    return [
      <DownloadButtonMenu key="download" where={where} />,
      <Visible key="new" to={AppPermissions.CreateJournalEntries}>
        <Button key="new" type="primary" to={financePath({ baseExtension: 'journalEntries', action: 'new' })}>
          <Text id="Finance.JournalEntries.add" />
        </Button>
      </Visible>
    ];
  }, [isV1, where]);

  return (
    <OverviewListV2
      withFilter={!isV1}
      totalNumResults={totalResults}
      items={transactions}
      filterDisabled={isLoading}
      actions={actions}
      labelId="Finance.JournalEntries.Transactions.List.label"
      table={<JournalEntriesTable transactions={transactions} {...r} />}
    />
  );
}
