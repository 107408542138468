import { useCallback } from 'react';

import pick from 'lodash/fp/pick';

import { MemberType } from '@kwara/models/src/models/Member';
import { LoanRemittance } from '@kwara/models/src/models/Remittance';
import { MobilePayoutSettings } from '@kwara/models/src/models/OrganisationSetting';

import { Store } from '../../../models/Store/Store';
import { YES, isDoneAddingCollateral } from './components/Security/Collateral';
import { useSaccoProfileContext } from '../../../models/Profile/ProfileProvider';

export function useGetInitialData() {
  const store: Store = useSaccoProfileContext();

  const mobilePayoutSettings = store?.organisationSettings?.mobilePayoutSettings || ({} as MobilePayoutSettings);

  const getInitialData = useCallback(
    (member: MemberType | null, isV1: boolean) => {
      const base = {
        repaymentPeriodUnit: 'MONTHS',
        repaymentPeriod: 1,
        hasMember: false,
        collaterals: [],
        guarantors: [],
        loans: isV1 ? [] : null,
        charges: [],
        chargeOptions: [],
        feeApplications: [],
        isV1: isV1,
        remittance: new LoanRemittance(),
        phoneNumberProvider: null,
        payoutProvider: mobilePayoutSettings.payout_provider,
        transactionAmountLimit: mobilePayoutSettings.transaction_amount_limit,
        [isDoneAddingCollateral]: YES
      };

      if (!member) return base;

      return {
        ...base,
        member: member.deserialize(),
        hasMember: true,
        fullName: member.fullName(),
        ...pick(['firstName', 'standing'], member)
      };
    },
    [mobilePayoutSettings.payout_provider, mobilePayoutSettings.transaction_amount_limit]
  );

  return getInitialData;
}
