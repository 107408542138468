import compact from 'lodash/fp/compact';

import { MemberType } from '@kwara/models/src';

import { LoanProduct, LoanProductV1 } from './components/LoanProduct';
import { LoanConfigure } from './components/LoanConfigure';
import PayOff from './components/PayOff';
import SecurityV2 from './components/SecurityV2';
import { FindMember } from './components/FindMember';
import { LoanPortfolio } from './components/LoanPortfolio';
import Charges from './components/Charges';
import MemberProfile from './components/MemberProfile';
import { SummaryAndReview } from './components/SummaryAndReview/SummaryAndReview';

export const steps = (member: MemberType, hasAddedMember: boolean, isV1: boolean) => ({
  loanPortfolio: {
    titleId: 'LoanAdd.LoanPortfolio.LeftNavigation.title',
    subtitleId: 'LoanAdd.LoanPortfolio.LeftNavigation.subTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'member'
      }
    ],
    children: compact([
      member
        ? null
        : {
            titleId: 'LoanAdd.FindAMember.title',
            Component: FindMember,
            hideActions: !hasAddedMember
          },
      {
        titleId: 'LoanAdd.LoanPortfolio.title',
        Component: LoanPortfolio,
        validate: LoanPortfolio.validate
      }
    ])
  },
  member: {
    titleId: 'LoanAdd.MemberProfile.LeftNavigation.title',
    subtitleId: 'LoanAdd.MemberProfile.LeftNavigation.subTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'product'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.MemberProfile.title',
        Component: MemberProfile.Component,
        validate: MemberProfile.validate
      }
    ]
  },
  product: {
    titleId: 'LoanAdd.Product.shortTitle',
    subtitleId: 'LoanAdd.Product.LeftNavigation.subTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: isV1 ? 'next' : 'nextWithPromise',
        destination: 'payOff',
        onNext: LoanConfigure.verifyTwoThirdsRule
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.LoanProduct.title',
        Component: isV1 ? LoanProductV1 : LoanProduct,
        hideActions: { next: true }
      },
      {
        titleId: 'LoanAdd.Configure.title',
        Component: LoanConfigure,
        validate: LoanConfigure.validate,
        hideErrorBanner: true
      }
    ]
  },
  payOff: {
    titleId: 'LoanAdd.PayOff.LeftNavigation.title',
    subtitleId: 'LoanAdd.PayOff.LeftNavigation.subTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'security'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.PayOff.title',
        Component: PayOff.Component,
        validate: PayOff.validate
      }
    ]
  },
  security: {
    titleId: 'LoanAdd.Security.LeftNavigation.title',
    subtitleId: 'LoanAdd.Security.LeftNavigation.subTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: isV1 ? 'charges' : 'summary'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Security.loanSecurity',
        Component: SecurityV2.Component,
        validate: SecurityV2.validate
      }
    ]
  },
  charges: {
    titleId: 'LoanAdd.Charges.LeftNavigation.title',
    subtitleId: 'LoanAdd.Charges.LeftNavigation.subTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Charges.loanCharges',
        Component: Charges.Component,
        validate: Charges.validate
      }
    ]
  },
  summary: {
    titleId: 'LoanAdd.SummaryAndReview.title',
    subtitleId: 'LoanAdd.SummaryAndReview.LeftNavigation.subTitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.SummaryAndReview.title',
        Component: SummaryAndReview
      }
    ]
  }
});
