import React from 'react';

import { If } from '@kwara/components/src/If/If';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { Actions } from '@kwara/models/src/models/LoanApplication';
import { SubscribedDatePicker } from '@kwara/components/src/Form';
import { InstantMobileMoneyValidator } from '@kwara/components/src/InstantMobileMoneyValidator';

import { useAuth } from '../../../../../hooks';
import { useAttributes } from './useAttributes';
import { FirstDisburser } from './FirstDisburser';
import { SecondDisburser } from './SecondDisburser';
import { useBaseLogic } from './useBaseLogic';
import { Container } from '../../../../../layouts';
import { V1PaymentMethod } from '../../../../../components/v1/V1PaymentMethod';
import { LoanDisbursalPropTypes, LoanDisbursalData } from '../..';
import { DisbursementMethodForm } from './DisbursementMethodForm';
import { DisbursementStatusBanner } from './DisbursementStatusBanner';
import { AttributesRow } from '../../../../../components/AttributesRow';
import { validateDisbursementAmount } from '../../../utils/validateDisbursementAmount';

import styles from './index.module.scss';

export function Approvals(props: LoanDisbursalPropTypes) {
  const {
    data: { loan, transactionAmountLimit, payoutProvider, amount },
    addData,
    TextField
  } = props;

  const auth = useAuth();
  const { attributeOne, attributeTwo, disbursement } = useAttributes(loan, addData);
  const {
    firstDisburser,
    secondDisburser,
    isFirstDisburserActive,
    disbursementAmount,
    phoneNumberProvider,
    isSecondDisburserActive,
    isSecondDisburserRequired
  } = useBaseLogic({ ...props, disbursement });

  const isV1 = auth.isV1();
  const isRefinanceAction = loan.loanApplication?.action === Actions.REFINANCE;

  return (
    <>
      <Container.Root>
        <Container.Body>
          <AttributesRow attributes={attributeOne} />
        </Container.Body>
      </Container.Root>
      <Container.Root>
        <Container.Heading id="LoanDisbursal.Approvals.disbursement" />
        <Container.Body>
          <AttributesRow attributes={attributeTwo} />
        </Container.Body>
      </Container.Root>
      <Container.Root>
        <Container.Body>
          <If
            condition={!isV1 && isFirstDisburserActive}
            do={
              <InstantMobileMoneyValidator
                loanAmount={disbursementAmount}
                payoutProvider={payoutProvider}
                phoneNumberProvider={phoneNumberProvider}
                transactionAmountLimit={transactionAmountLimit}
              />
            }
          />
          <div className={styles.grid}>
            {isV1 ? (
              <>
                <V1PaymentMethod />
                <SubscribedDatePicker
                  name="valueDate"
                  size="medium"
                  labelId="LoanDisburse.dateLabel"
                  disabled={isRefinanceAction}
                  disabledDays={{ after: getCurrentDate() }}
                  required
                />
                <TextField
                  initialValue={amount}
                  type="number"
                  name="v1Amount"
                  labelId="LoanDisbursal.Approvals.V1DisbursalAmount.label"
                  leftGlyph="Currency.orgCurrency"
                  margin={false}
                  isCurrency
                  required
                />
              </>
            ) : (
              <>
                <FirstDisburser
                  {...props}
                  firstDisburser={firstDisburser}
                  disbursementAmount={disbursementAmount}
                  isFirstDisburserActive={isFirstDisburserActive}
                />
                <SecondDisburser
                  {...props}
                  secondDisburser={secondDisburser}
                  isFirstDisburserActive={isFirstDisburserActive}
                  isSecondDisburserActive={isSecondDisburserActive}
                  isSecondDisburserRequired={isSecondDisburserRequired}
                />
              </>
            )}
          </div>
          {isV1 ? null : <DisbursementStatusBanner disbursement={disbursement} />}
        </Container.Body>
      </Container.Root>
    </>
  );
}

export default {
  Component: Approvals,
  validate: {
    v1Amount: {
      isRequired: (_: string, { isV1 }: LoanDisbursalData) => isV1
    },
    disburserOneAmount: {
      isRequired: (_: string, { loan, isV1 }: LoanDisbursalData) => !loan.hasDisbursementRecord() && !isV1,
      custom: validateDisbursementAmount
    },
    disburserTwoAmount: {
      isRequired: (_: string, { loan, isV1 }: LoanDisbursalData) => loan.hasDisbursementRecord() && !isV1,
      custom: validateDisbursementAmount
    },
    custom: (_: string, { loan, isV1 }: LoanDisbursalData) => {
      if (!loan.hasDisbursementRecord() && !isV1) return DisbursementMethodForm.validate;
      return null;
    }
  }
};
