import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Loadable } from '@kwara/components/src/Loadable';
import { useSaving } from '@kwara/models/src/models/request/hooks';
import { SavingsTransaction, SavingType } from '@kwara/models/src';
import { formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';

import Wizard from '../../../../components/Wizard';
import { WizardPageProps } from '../../..';
import { savingPath } from '../../../../lib/urls';
import { steps } from './steps';

type Props = WizardPageProps<{ memberId: string }>;

type TransactionFormDataT = {
  type: 'DEPOSIT';
  accountId: string;
  amount: number;
  method: string;
  notes: string;
  reference: string;
  valueDate: string;
};

async function addDeposit(data: TransactionFormDataT) {
  const { amount, accountId, method, notes, reference, valueDate } = data;

  const transaction = SavingsTransaction.create({
    type: 'DEPOSIT',
    savingsId: accountId,
    amount,
    paymentMethodId: method,
    notes,
    reference,
    valueDate: formatIsoDate(valueDate)
  });

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
}

export function SavingsDeposit(props: Props) {
  const queryClient = useQueryClient();
  const savingId = props.match.params.savingId;
  const r = useSaving(savingId);

  return (
    <Loadable {...r}>
      {(saving: SavingType) => {
        const member = saving.member;

        return saving.isLive() ? (
          <Wizard
            analyticsId="SavingsDeposit"
            baseUrl={savingPath({ id: saving.id })}
            history={history}
            initialData={{
              member,
              saving,
              accountId: savingId,
              valueDate: getCurrentDate()
            }}
            cancelReturnsTo={savingPath({ id: saving.id })}
            currentStep="deposit"
            onSubmit={addDeposit}
            onSubmitCompletion={() => {
              r.refetch();
              queryClient.invalidateQueries('member_details_metrics');
            }}
            steps={steps}
            startId="deposit"
            titleId="SavingPayment.title"
          />
        ) : (
          <Redirect to={savingPath({ id: member.id })} />
        );
      }}
    </Loadable>
  );
}
