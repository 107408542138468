import React from 'react';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';

import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { LoanAppraisalLocationParams, Notes, ApproveArgs, MemberFetcherPropTypes } from 'LoanAppraisalTypes';

import Wizard from '@kwara/components/src/Wizard/latest';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';
import { useFullLoan } from '@kwara/models/src/models/request/hooks';
import { isStandardIdDocumentType } from '@kwara/components/src/Intl';
import { LoanType, MemberType, IdDocumentType } from '@kwara/models/src';

import { steps } from './config';
import { loanPath } from '../../../lib/urls';
import { usePermissions, useAuth } from '../../../hooks';
import { Store } from '../../../models/Store/Store';
import { CreditCheckImp } from '../components/CreditCheck/CreditCheckImp';
import { useSaccoProfileContext } from '../../../models/Profile/ProfileProvider';

/**
 * @prepareNotes takes in a `notes` object and returns an array of objects containing
 * information about each note, filtered to exclude any notes with empty values.
 * @param {Notes} notes - The `notes` parameter is an object that represents notes for different steps
 * and sections. It has the following structure:
 * @returns an array of objects. Each object in the array has the properties `flow`, `step`, `section`,
 * and `value`.
 */
export function prepareNotes(notes: Notes = {}) {
  return Object.keys(notes)
    .map(step => {
      const section = Object.keys(notes[step])[0];
      const value = notes[step][section];

      return { flow: 'loan_appraisal', step, section, value };
    })
    .filter(obj => !!obj.value);
}

/**
 * @approve function takes in an argument with various loan details and approves the loan if all
 * conditions are met.
 * @param {ApproveArgs} arg - The `arg` parameter is an object that contains the following properties:
 */
async function approve(arg: ApproveArgs) {
  const {
    loan,
    notes,
    disbursementType,
    recommendedAmount,
    anticipatedDisbursementDate,
    anticipatedFirstRepaymentDate
  } = arg;
  const application_notes = prepareNotes(notes);
  const didApprove = await loan.approve({
    application_notes,
    disbursement_type: disbursementType,
    recommended_amount: recommendedAmount,
    anticipated_disbursement_date: anticipatedDisbursementDate && formatIsoDate(anticipatedDisbursementDate),
    anticipated_first_repayment_date: anticipatedFirstRepaymentDate && formatIsoDate(anticipatedFirstRepaymentDate)
  });

  if (!didApprove) throw loan.errors;
}

/**
 * @reject function rejects a loan and throws an error if the rejection fails.
 * @param  - The `reject` function takes an object as a parameter with a property `loan` of type
 * `LoanType`. The `loan` object represents a loan and has a method `reject` that is awaited. The
 * `reject` method is expected to return a boolean value indicating whether the rejection was
 * successful
 */
async function reject({ loan }: { loan: LoanType }) {
  const didReject = await loan.reject();

  if (!didReject) throw loan.errors;
}

/**
 * @MemberFetcher function is a React component that fetches data related
 * to a loan member and renders a `Wizard` component with the fetched data.
 */
function MemberFetcher({ loan, params }: MemberFetcherPropTypes) {
  const queryClient = useQueryClient();
  const memberR = useMember(loan.member.id);
  const { permission, AppPermissions } = usePermissions();
  const { isKwaraKenyaOrE2E }: Store = useSaccoProfileContext();
  const auth = useAuth();
  const getData = React.useCallback(
    (member: MemberType) => {
      /**
       * KBS has idDocument type: Historical Member ID,
       * we need to ensure to pick either Passport or National
       */
      const idDocument = find(
        (idDocument: IdDocumentType) => isStandardIdDocumentType(idDocument.type),
        get('idDocuments', member)
      );

      return { loan, identityValue: get('documentId', idDocument), identityType: get('type', idDocument) };
    },
    [loan]
  );

  const canApprove = permission.to(AppPermissions.ApproveLoans);
  const canReject = permission.to(AppPermissions.RejectLoans);
  const isV1 = auth.isV1();

  return (
    <Loadable {...memberR}>
      {(member: MemberType) => {
        const data = getData(member);

        return (
          <CreditCheckImp data={data}>
            {args => (
              <Wizard
                type="approval"
                startId="memberProfile"
                analyticsId="LoanAppraisal"
                titleId="LoanAppraisal.title"
                steps={steps({ ...args, canApprove, canReject, isKwaraKenyaOrE2E, isV1 })}
                baseUrl={`/loans/${params.loanId}/appraisal`}
                onReject={reject}
                onSubmit={approve as any}
                currentStep={params.step}
                cancelReturnsTo={loanPath()}
                currentSubStep={params.subStep != undefined ? parseInt(params.subStep, 10) : null}
                onSubmitCompletion={() => queryClient.removeQueries('loan')}
                initialData={{
                  ...data,
                  member,
                  loans: member.loans,
                  collaterals: loan.collaterals,
                  guarantors: loan.guarantors,
                  fullName: loan.member.fullName(),
                  product: loan.product,
                  amount: loan.amount,
                  remittance: loan.remittance ?? { amount: 0 }
                }}
              />
            )}
          </CreditCheckImp>
        );
      }}
    </Loadable>
  );
}

/**
 * @LoanAppraisal component fetches loan and activity data, filters the activities for comments, and
 * renders the MemberFetcher component with the fetched data.
 * @param props - The `props` parameter is an object that contains a property called `baseUrl` of type
 * string.
 * @returns The function `LoanAppraisal` is returning a JSX element.
 */
export default function LoanAppraisal() {
  const params = useParams<LoanAppraisalLocationParams>();
  const loanR = useFullLoan(params.loanId);

  return <Loadable {...loanR}>{(loan: LoanType) => <MemberFetcher loan={loan} params={params} />}</Loadable>;
}
