import { InferredModel } from 'GlobalTypes';
import { hasMany } from 'spraypaint';

import Base from '../../Base';
import { TrialBalanceRowT } from './Row';

export const TrialBalance = Base.extend({
  static: {
    jsonapiType: 'abstract_trial_balance_reports',
    endpoint: '/accounting/reports/trial_balance'
  },
  attrs: {
    rows: hasMany('abstract_trial_balance_report_rows')
  }
});

export interface TrialBalanceT extends Omit<InferredModel<typeof TrialBalance>, 'errors'> {
  rows: TrialBalanceRowT[];
}
