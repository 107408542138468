import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const Charge = Base.extend({
  static: {
    jsonapiType: 'loan_charges'
  },
  attrs: {
    name: attr(),
    amount: attr(),
    calculationType: attr(),
    chargeTime: attr(),

    // write
    chargeId: attr()
  }
});

export interface LoanProductChargeT extends Omit<InferredModel<typeof Charge>, 'errors'> {
  name: string;
  amount: string;
  calculationType: string;
  chargeTime: string;
  chargeId: string;
}
