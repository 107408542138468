import { InferredModel } from 'GlobalTypes';
import { hasMany } from 'spraypaint';

import Base from '../../Base';
import { BalanceSheetRowT } from './Row';

export const BalanceSheet = Base.extend({
  static: {
    jsonapiType: 'abstract_balance_sheet_reports',
    endpoint: '/accounting/reports/balance_sheet'
  },
  attrs: {
    rows: hasMany('abstract_balance_sheet_report_rows')
  }
});

export interface BalanceSheetT extends Omit<InferredModel<typeof BalanceSheet>, 'errors'> {
  rows: BalanceSheetRowT[];
}
