import { InferredModel } from 'GlobalTypes';
import { hasMany } from 'spraypaint';

import Base from '../../Base';
import { ProfitLossRowT } from './Row';

export const ProfitLoss = Base.extend({
  static: {
    jsonapiType: 'abstract_profit_loss_reports',
    endpoint: '/accounting/reports/profit_loss'
  },
  attrs: {
    rows: hasMany('abstract_profit_loss_report_rows')
  }
});

export interface ProfitLossT extends Omit<InferredModel<typeof ProfitLoss>, 'errors'> {
  rows: ProfitLossRowT[];
}
