import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember, useMemberLoans } from '@kwara/models/src/models/request/hooks';
import { LoanTransaction, MemberType, LoanType } from '@kwara/models/src';
import { formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';

import Wizard from '../../../../components/Wizard';
import { WizardPageProps } from '../../..';
import { memberPath } from '../../../../lib/urls';

import { steps } from './steps';

type Props = WizardPageProps<{ memberId: string }>;

type TransactionFormDataT = {
  accountId: string;
  amount: number;
  method: string;
  notes: string;
  reference: string;
  valueDate: string;
};

async function addRepayment(data: TransactionFormDataT) {
  const { amount, accountId, method, notes, reference, valueDate } = data;

  const transaction = LoanTransaction.create({
    type: 'REPAYMENT',
    loanId: accountId,
    amount,
    paymentMethodId: method,
    notes,
    reference,
    valueDate: formatIsoDate(valueDate)
  });

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
}

export function MemberRepayment(props: Props) {
  const queryClient = useQueryClient();
  const memberId = props.match.params.memberId;
  const r = useMember(memberId);
  const loansR = useMemberLoans(memberId);

  return (
    <Loadable {...r}>
      {(member: MemberType) => (
        <Loadable {...loansR}>
          {(loans: LoanType[]) => {
            member.loans = loans;

            const redirectTo = memberPath({ id: member.id });
            const loansEligibleForRepayment = member.loansEligibleForRepayment();
            const isEligible = loansEligibleForRepayment.length > 0;

            return isEligible ? (
              <Wizard
                analyticsId="MemberRepayment"
                baseUrl={memberPath({ id: member.id })}
                history={history}
                initialData={{
                  member,
                  accounts: loansEligibleForRepayment,
                  accountId: loansEligibleForRepayment[0].id,
                  valueDate: getCurrentDate()
                }}
                cancelReturnsTo={redirectTo}
                currentStep="repayment"
                onSubmit={addRepayment}
                onSubmitCompletion={() => {
                  loansR.refetch();
                  queryClient.invalidateQueries('member_details_metrics');
                }}
                steps={steps}
                startId="repayment"
                titleId="LoanRepayment.title"
              />
            ) : (
              <Redirect to={memberPath({ id: member.id })} />
            );
          }}
        </Loadable>
      )}
    </Loadable>
  );
}
