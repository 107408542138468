import { InferredModel } from 'GlobalTypes';
import { attr, hasMany } from 'spraypaint';

import Base from '../../Base';
import { GlReportRowT } from './Row';

export const GlReport = Base.extend({
  static: {
    jsonapiType: 'abstract_general_ledger_reports',
    endpoint: '/accounting/reports/general_ledger'
  },
  attrs: {
    openingBalance: attr(),
    closingBalance: attr(),
    rows: hasMany('abstract_general_ledger_report_rows')
  }
});

export interface GlReportT extends Omit<InferredModel<typeof GlReport>, 'errors'> {
  rows: GlReportRowT[];
  openingBalance: number;
  closingBalance: number;
}
