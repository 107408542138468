import React from 'react';
import { useQuery } from 'react-query';
import { GlReport } from '@kwara/models/src/models/v1/GLReport/Report';
import { formatIsoDate } from '@kwara/lib/src/dates';

async function fetchGlReport({ queryKey }) {
  const [_key, startDate, endDate, glAccountId] = queryKey;

  return GlReport.extraParams({
    gl_account_id: glAccountId,
    start_date: formatIsoDate(startDate),
    end_date: formatIsoDate(endDate)
  })
    .find()
    .then(res => res.data);
}

export function useGLReport({ startDate, endDate, glAccountId }) {
  return useQuery(['gl_report', startDate, endDate, glAccountId], fetchGlReport, {
    enabled: !!startDate && !!endDate && !!glAccountId,
    retry: false
  });
}

export function useGlReportsPage() {
  const [params, setParams] = React.useState({
    startDate: null,
    endDate: null,
    glAccountId: 'a7ac689e-6aeb-4772-8585-bf973802e01e'
  });

  const { data: report, isLoading } = useGLReport(params);

  const onSubmit = React.useCallback(data => setParams(prev => ({ ...prev, ...data })), []);

  return { report, onSubmit, isLoading };
}
